import { hover } from "@testing-library/user-event/dist/hover";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ReactPaginate from "react-paginate";
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Loader from "../Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Magnifier } from "react-image-magnifiers";
import ModalVideo from "react-modal-video";
import ReactPlayer from "react-player";
import { FaCheckCircle } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { Skeleton } from "antd";
import "react-modal-video/css/modal-video.min.css";
const ProductCategory = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { name } = useParams();
  const locationdata = useLocation();
  const cat_n = locationdata.state.name;
  const cat_ids = locationdata.state.cat_id;
  const cartpopup = useRef(null);
  const quickpopupclose = useRef(null);
  const quickpopup = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
  const configToken = {
    headers: {
      Authorization: `Bearer ${localdata.data.token}`,
    },
  };
  const [productGalleryData, setProductGalleryData] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const handleMainImageClick = (index) => {
    setMainImageIndex(index);
  };
  const [isOpenV, setOpenV] = useState(false);

  const openVModal = () => {
    setOpenV(true);
  };

  const closeVModal = () => {
    setOpenV(false);
  };

  // sub-category pagination
  const [perPage, setPerPage] = useState(10); // Number of items per page
  const [currentPages, setCurrentPages] = useState(0);
  // const handlePageClick = (selectedPage) => {
  //    setCurrentPages(selectedPage.selected);
  //  };

  const [displayedItems, setDisplayedItems] = useState(perPage);

  const handleShowMore = () => {
    setDisplayedItems(subcategorydetail.length);
  };
  //   end sub-category pagination
  // category filter start here
  const [brand, setbrand] = useState(true);
  const [category, setcategory] = useState(false);
  const [categorystandards, setcategorystandards] = useState(false);
  const [price, setprice] = useState(true);
  const [discount, setdiscount] = useState(true);
  const [order, setorder] = useState("DESC");
  const [orderby, setorderby] = useState("id");

  const [projects, setProjects] = useState([]);
  const [projects1, setProjects1] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [TotalPerPages, setTotalPerPages] = useState("");
  //filter data get like category,brand and all
  const [category_data, setCategory] = useState([]);
  const [price_data, setPrice] = useState([]);
  const [brand_data, setBrand] = useState([]);
  const [discount_data, setDiscount] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [productId, setProductId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [cartproductId, setCartProductId] = useState(null);
  const [product_de, setProduct_de] = useState(null);
  const [cartproductDetails, setCartProductDetails] = useState(null);
  const [productSKUDetails, setRelatedSKUDetails] = useState(null);
  const [singlesku, setSingleSKU] = useState(null);

  const [categorydetail, setCategoryDetail] = useState([]);
  const [subcategorydetail, setSubCategoryDetail] = useState([]);
  const [hovereye, sethovereye] = useState(0);
  const [showModal, setShowModal] = useState(true);
  const [quantityvalidation, setQuantityValidation] = useState(0);
  const [varient, setvarient] = useState({});
  const [productids, setProductIds] = useState("");
  const [convertedArray, setConvertedArray] = useState([]);
  const [addtocartresponseMessage, setAddCartResponseMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const [totalPrices, setTotalPrices] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const [quantityValues, setQuantityValues] = useState({});

  const handleAddCartAlertClick = () => {
    setError(false);
  };
  const handleAlertClick = () => {
    setResponseMessage(false);
  };

  const handleQuantityChange = (variantId, quantity, price, pro_id) => {
    varient[variantId] = parseInt(quantity);
    setTimeout(() => {
      setError("");
    }, 1000);
    setProductIds(pro_id);
    // console.log('proid:',pro_id);
    // console.log('varient detail:',variantId,quantity,varient);
    const newArray = Object.entries(varient)
      .filter(([variant_id, quantity]) => quantity > 0) // Only include entries with quantity > 0
      .map(([variant_id, quantity]) => ({
        variant_id,
        quantity: String(quantity),
      })); // Map to the desired format
    // console.log("convert to:",newArray);
    setConvertedArray(newArray);
    // console.log('v:',variantId);
    // console.log('p:',price);
    // console.log('q:',quantity);
    // setQuantityValidation(quantity);
    // if (quantity != '' && quantity.length > 1) {
    //   setError('');
    // }else{
    //   setError('Please Select Size and Add Quantity');
    // }
    // if (quantity !== 0) {
    //   setError('');
    // }
    setQuantityValues((prevQuantities) => ({
      ...prevQuantities,
      [variantId]: parseInt(quantity, 10),
    }));
    setTotalPrices((prevTotalPrices) => ({
      ...prevTotalPrices,
      [variantId]: parseFloat(price) * parseInt(quantity, 10),
    }));
  };

  useEffect(() => {
    const initialTotalPrices = {};
    Object.keys(quantityValues).forEach((variantId) => {
      initialTotalPrices[variantId] =
        parseFloat(product_de.uprice) * quantityValues[variantId];
    });
    setTotalPrices(initialTotalPrices);

    const fetchCategoryDetail = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiUrl}/productCategory/${cat_n}`,
          configToken
        );
        setCategoryDetail(response?.data?.data);
        setSubCategoryDetail(response?.data?.data?.child_categoires);
        //   console.log('Fetched Data:', response.data.data?.child_categoires);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile data:", error);
        // console.error('Axios error details:', error.response); // Log the response for more details
        setLoading(false);
      }
    };

    const queryParams = new URLSearchParams();
    //   queryParams.set('page', currentPage);
    queryParams.set("cat_id", cat_ids);
    const fetchProjects = async () => {
      try {
        setLoadingProducts(true);
        //   const response = await fetch(
        //     `${apiUrl}/products?page=${currentPage}&orderby=${orderby}&order=${order}`,
        //     configToken
        //   );
        const response = await fetch(
          `${apiUrl}/products?cat_id=${cat_ids}&page=${currentPage}`,
          configToken
        );
        const data = await response.json();
        setProjects(data.data.data);
        setProjects1(data.data);
        setTotalPages(data.data.last_page);
        setTotalPerPages(data.data.per_page);
        // setPageLinks(data.data.links);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingProducts(false);
      }
    };

    const fetchFilterValue = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/filterList`);
        const data = await response.json();
        setCategory(data.data.Category);
        setPrice(data.data.Price_in_AED);
        setBrand(data.data.Brands);
        setDiscount(data.data.Discount);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching best sellers data:", error);
        setLoading(false);
      }
    };

    fetchProjects();
    fetchFilterValue();
    fetchCategoryDetail();
  }, [
    currentPage,
    name,
    cat_ids,
    cat_n,
    setProjects,
    setProjects1,
    currentPages,
    perPage,
  ]);

  // filter of price, brand, discount
  const [filterbybrand, setfilterbybrand] = useState([]);
  const [filterbydiscount, setfilterbydiscount] = useState([]);
  const [filterbyprice, setfilterbyprice] = useState([]);
  const changestate = (statename, valuedata, identify) => {
    statename((prevData) => {
      const updatedData = [...prevData];

      const index = updatedData.indexOf(valuedata);

      if (index !== -1) {
        // Value exists in the array, remove it
        updatedData.splice(index, 1);
      } else {
        // Value doesn't exist in the array, add it
        updatedData.push(valuedata);
      }
      // responsevalue = updatedData;
      handlePageChange(identify, updatedData);
      return updatedData;
    });
  };
  // filter of price, brand, discount

  // filter of pagination
  const [currentpageno, setcurrentpageno] = useState(1);
  const changepage = (e) => {
    setcurrentpageno(e);
    handlePageChange("page", e);
  };
  // filter of pagination

  // filter of sort by
  const [orderbyval, setorderbyval] = useState("id");
  const [orderval, setorderval] = useState("DESC");
  const sortbyfun = (e) => {
    // console.log("e.vv", e.target.value);
    if (e.target.value == "ASC" || e.target.value == "DESC") {
      setorderbyval("price");
      setorderval(e.target.value);
      handlePageChange("orderby", "price");
      handlePageChange("order", e.target.value);
    } else {
      // updateDataValue("orderby", e.target.value);
      setorderbyval(e.target.value);
      handlePageChange("orderby", e.target.value);
    }
  };
  // filter of sort by

  //   fetch products
  const handlePageChange = async (statename, value) => {
    let brandvalue = "";
    let pricevalue = "";
    let discountvalue = "";
    let orderby = "";
    let order = "";
    let url = "";
    let page = 1;
    let finalstring = "";
    if (statename == "orderby") {
      orderby = value;
      page = 1;
    } else {
      orderby = orderbyval;
    }
    if (statename == "page") {
      page = value;
    } else {
      page = currentpageno;
    }
    if (statename == "order") {
      order = value;
      page = 1;
    } else {
      order = orderval;
    }
    if (statename == "filterbybrand") {
      brandvalue = value.join(",");
      page = 1;
    } else {
      brandvalue = filterbybrand.join(",");
    }
    if (statename == "filterbyprice") {
      pricevalue = value.map((item) => `prices[]=${item}`).join("&");
      page = 1;
    } else {
      pricevalue = filterbyprice.map((item) => `prices[]=${item}`).join("&");
    }
    if (statename == "filterbydiscount") {
      discountvalue = value.map((item) => `discounts[]=${item}`).join("&");
      page = 1;
    } else {
      discountvalue = filterbydiscount
        .map((item) => `discounts[]=${item}`)
        .join("&");
    }
    if (brandvalue == "") {
      if (pricevalue == "") {
        if (discountvalue == "") {
          finalstring = null;
        } else {
          finalstring = `${discountvalue}`;
        }
      } else {
        if (discountvalue == "") {
          finalstring = `${pricevalue}`;
        } else {
          finalstring = `${pricevalue}&${discountvalue}`;
        }
      }
    } else {
      if (pricevalue == "") {
        if (discountvalue == "") {
          finalstring = `brand_ids=${brandvalue}`;
        } else {
          finalstring = `brand_ids=${brandvalue}&${discountvalue}`;
        }
      } else {
        if (discountvalue == "") {
          finalstring = `brand_ids=${brandvalue}&${pricevalue}`;
        } else {
          finalstring = `brand_ids=${brandvalue}&${pricevalue}&${discountvalue}`;
        }
      }
    }
    console.log(
      brandvalue,
      "&",
      pricevalue,
      "&",
      discountvalue,
      "sdfghjdfghsdsg",
      finalstring
    );
    setLoading(true);
    try {
      if (finalstring == null) {
        // const response = await axios.get(`${apiUrl}/products?cat_id=${cat_ids}&page=${currentPage}&orderby=${orderby}&order=${order}`, configToken);
        url = `${apiUrl}/products?cat_id=${cat_ids}&page=${page}&orderby=${orderby}&order=${order}`;
        //   url = `${apiUrl}/products?page=${page}&orderby=${orderby}&order=${order}`;
      } else {
        url = `${apiUrl}/products?cat_id=${cat_ids}&page=${page}&${finalstring}&orderby=${orderby}&order=${order}`;
        //   url = `${apiUrl}/products?page=${page}&${finalstring}&orderby=${orderby}&order=${order}`;
      }
      const response = await fetch(url, configToken);
      const data = await response.json();
      setProjects(data.data.data);
      setProjects1(data.data);
      setTotalPages(data.data.last_page);
      setTotalPerPages(data.data.per_page);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  //   fetch products
  //   fetch products with param parameters start here
  const [checkboxStates, setCheckboxStates] = useState(Array(5).fill(false)); // Assuming 5 rows
  const [activeInput, setActiveInput] = useState(null);
  const inputRefs = useRef(
    Array(5)
      .fill(null)
      .map(() => React.createRef())
  );

  const handleInputChange = (index) => {
    // Toggle the checkbox state for the clicked row
    setCheckboxStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });

    // Set the active input to the clicked input
    setActiveInput(index);
  };

  const handleClickOutside = (event) => {
    // Uncheck all checkboxes when clicking outside of any input
    if (
      !inputRefs.current.some(
        (ref) => ref.current && ref.current.contains(event.target)
      )
    ) {
      setCheckboxStates(Array(5).fill(false));
      setActiveInput(null);
    }
  };

  // start api for model popup
  const handleQuickButtonClick = (id) => {
    if (quickpopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(quickpopup.current);
      modal.show();
    }
    setProductId(id);
    //  console.log('id:',id);
    const fetchQuickModelValue = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/products/${id}`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setProductDetails(response.data.data);
        setWishlist(response?.data?.data?.wishlist);
        setProductGalleryData(response?.data?.data?.gallery);
        //   console.log('pro de:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchQuickModelValue();
  };

  const handleAddCartButtonClick = (id, name, sku, qyt) => {
    if (quickpopupclose.current) {
      // Trigger the click event on the button
      quickpopupclose.current.click();
    }
    if (cartpopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(cartpopup.current);
      modal.show();
    }
    setProductIds(id);
    // console.log('proid:',id);
    const fetchProductValue = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/products/${id}`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setProduct_de(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    const fetchAddCartModelValue = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/products/porductVariant?product_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        setCartProductDetails(response.data.data);
        const updatedAttributes = {};
        response.data.data.forEach((item, index) => {
          updatedAttributes[item.id] = 0;
        });
        setvarient((prevData) => ({
          ...prevData,
          ...updatedAttributes,
        }));
        //  console.log('pro cart de:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const fetchRelatedSKUValue = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/getRelatedProductsBySKU?sku=${sku}`,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        setRelatedSKUDetails(response.data.data);
        // console.log('pro cr sku:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchRelatedSKUValue();
    fetchProductValue();
    fetchAddCartModelValue();
  };
  // Function to handle form submission
  // if (!/^\d+$/.test(quantityvalidation) || quantityvalidation <= 0) {
  // console.log('conv array submit:',convertedArray);

  const handleAddToCartSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple clicks

    setIsSubmitting(true);

    // if (!/^\d+$/.test(quantityvalidation) || quantityvalidation <= 0) {
    //   if(quantityvalidation <= 0){
    //   setError('Please Select Size and Add Quantity.');
    // } else {
    // Perform submit action or other logic here
    // console.log('quantity array:',quantityValues);
    // console.log('price array:',totalPrices);
    // console.log('Submit:', quantityvalidation);
    // }
    // const value = parseInt(quantityvalidation);

    // if (!isNaN(quantityvalidation) && quantityvalidation > 0 && quantityvalidation <= 1) {
    if (!convertedArray[0]) {
      // console.log('cons:',convertedArray);
      setError("Please Select Size and Add Quantity.");
    } else {
      // console.log('proids:',productids);
      // console.log('varient qyt array:',convertedArray);
      // Your submission logic here
      const collectdata = {
        product_id: productids,
        product_option: convertedArray,
      };
      // console.log('add to cart submit:',collectdata);

      setLoading(true);
      try {
        const response = await axios.post(`${apiUrl}/carts/add`, collectdata, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });

        if (response.data.success === true) {
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);

          setAddCartResponseMessage(response.data.message);

          setShowModal(false);
          toast.success(
            <div>
              <div>{response.data.message}</div>
              <button
                className="order-now"
                onClick={redirectToCart}
                style={{ marginTop: "10px" }}
              >
                Go to Cart
              </button>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );

          setTimeout(() => {
            window.location.reload(true);
          }, 2000); // 7000 milliseconds is the autoClose duration
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const redirectToCart = () => {
    // Redirect logic to the cart page; replace with your actual redirect logic
    console.log("Redirecting to the cart page...");
    // For demonstration purposes, let's assume the cart page has a path '/cart'
    // You may want to use react-router-dom or your preferred routing library for this
    // window.location.href = '/cart';
    nvg(`/cart`);
  };
  const handleProDetails = (pro_name) => {
    nvg(`/product-detail/${pro_name}`);
    window.location.reload();
  };

  const [error, setError] = useState("");
  const [serror, setsError] = useState("");
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    //   nvg(`/shop?search=${searchTerm}`);
    //   window.location.reload();
    if (searchTerm.trim() === "") {
      setsError(true);
    } else {
      setsError(false);
      // Perform your search logic here
      nvg(`/shop?search=${searchTerm}`);
      window.location.reload();
    }
  };
  const [wishlist, setWishlist] = useState([]);
  const handleAddWishlist = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };

    const fetchwishlistAd = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/addToWishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          // setProductData(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== product_id) : []);

          setWishlist(response.data.success);
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistAd();
  };

  const handleRemoveWishlist = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistRemove = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/RemoveFromwishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );

          setWishlist(false);
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistRemove();
  };

  const handleAddWishlistQuick = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistAd = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/addToWishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          // setProductData(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== product_id) : []);

          setWishlist(response.data.success);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistAd();
  };

  const handleRemoveWishlistQuick = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistRemove = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/RemoveFromwishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );

          setWishlist(false);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistRemove();
  };
  return (
    <div>
      <ToastContainer />
      {loading ? <Loader /> : null}
      <Helmet>
        <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section id="product-listing-sec" className="inner-page-bg mb-5">
        <div className="container">
          <div className="row">
            <div
              id="secondary"
              className="col-xl-3 col-lg-3 col-md-12 searchboxvalidation sidebarSbm"
            >
              <div className="search-container">
                <form id="searchPSide" onSubmit={handleSearchSubmit}>
                  <input
                    type="text"
                    placeholder="Search Products"
                    className={`searchText ${serror ? "error" : ""}`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
                {/* < input type="text" className="form-control" placeholder="Search Products " aria-label="Username" aria-describedby="addon-wrapping" /> */}
              </div>

              <div className="card">
                <div className=" collection-filter category-page-side">
                  {/* side-bar colleps block stat */}
                  <div className="collection-filter-block creative-card creative-inner category-side">
                    {/* category filter start  here*/}

                    <div className="box1">
                      <div className="collection-collapse-block open">
                        <h4
                          style={{
                            paddingBottom: category == true ? "0px" : "10px",
                          }}
                          className="collapse-block-title mt-0"
                          onClick={() => {
                            setcategory(!category);
                          }}
                        >
                          CATEGORY{" "}
                          {category == false ? (
                            <i
                              style={{
                                color: "#059fe2",
                                fontFamily: "fontAwesome",
                                fontWeight: "300",
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                top: "4px",
                              }}
                              className="fa-solid fa-minus"
                            ></i>
                          ) : (
                            <i
                              style={{
                                color: "#059fe2",
                                fontFamily: "fontAwesome",
                                fontWeight: "300",
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                top: "4px",
                              }}
                              className="fa-solid fa-plus"
                            ></i>
                          )}
                        </h4>
                        <div
                          className="collection-collapse-block-content"
                          style={{
                            display: category == true ? "none" : "block",
                          }}
                        >
                          <div className="collection-brand-filter">
                            {category_data.map((cat) => (
                              <div className="">
                                <label
                                  className="custom-control-label form-check-label boxsmalltext"
                                  htmlFor={cat.name}
                                >
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      nvg("/product-category-detail", {
                                        state: {
                                          name: cat.slug,
                                          cat_id: cat.id,
                                        },
                                      });
                                    }}
                                  >
                                    <span className="minus">
                                      <i className="fa-solid fa-minus"></i>
                                    </span>{" "}
                                    {cat.name}
                                  </a>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box1">
                      <div className="collection-collapse-block open">
                        <h3
                          className="collapse-block-title mt-0"
                          style={{
                            paddingBottom:
                              categorystandards == true ? "0px" : "10px",
                          }}
                          onClick={() => {
                            setcategorystandards(!categorystandards);
                          }}
                        >
                          CATEGORY STANDARDS
                          {categorystandards == false ? (
                            <i
                              style={{
                                color: "#059fe2",
                                fontFamily: "fontAwesome",
                                fontWeight: "300",
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                top: "4px",
                              }}
                              className="fa-solid fa-minus"
                            ></i>
                          ) : (
                            <i
                              style={{
                                color: "#059fe2",
                                fontFamily: "fontAwesome",
                                fontWeight: "300",
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                top: "4px",
                              }}
                              className="fa-solid fa-plus"
                            ></i>
                          )}
                        </h3>
                        <div
                          className="collection-collapse-block-content"
                          style={{
                            display:
                              categorystandards == true ? "none" : "block",
                          }}
                        >
                          <div className="collection-brand-filter">
                            <h6>{categorydetail.category_name} </h6>
                            {subcategorydetail.map((subcat) => (
                              <div className="">
                                <label
                                  className="custom-control-label form-check-label boxsmalltext"
                                  htmlFor={subcat.category_name}
                                >
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      nvg("/product-category-detail", {
                                        state: {
                                          name: subcat.slug,
                                          cat_id: subcat.id,
                                        },
                                      });
                                    }}
                                  >
                                    <span className="minus">
                                      <i className="fa-solid fa-minus"></i>
                                    </span>{" "}
                                    {subcat.category_name}
                                  </a>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* price filter start here */}
                    <div className="box2">
                      <div className="collection-collapse-block border-0 open">
                        <h4
                          style={{
                            paddingBottom: price == true ? "0px" : "10px",
                          }}
                          className="collapse-block-title"
                          onClick={() => {
                            setprice(!price);
                          }}
                        >
                          FILTER BY PRICE{" "}
                          {price == false ? (
                            <i
                              style={{
                                color: "#059fe2",
                                fontFamily: "fontAwesome",
                                fontWeight: "300",
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                top: "4px",
                              }}
                              className="fa-solid fa-minus"
                            ></i>
                          ) : (
                            <i
                              style={{
                                color: "#059fe2",
                                fontFamily: "fontAwesome",
                                fontWeight: "300",
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                top: "4px",
                              }}
                              className="fa-solid fa-plus"
                            ></i>
                          )}
                        </h4>
                        <div
                          className="collection-collapse-block-content slowslide"
                          style={{ display: price == false ? "block" : "none" }}
                        >
                          {price_data.map((pri) => (
                            <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
                              {filterbyprice.includes(pri.value) ? (
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-check-input"
                                  id={pri.name}
                                  onChange={() => {
                                    // setfilterbyprice("");
                                    // updateDataValue("prices[]", "");
                                    changestate(
                                      setfilterbyprice,
                                      pri.value,
                                      "filterbyprice"
                                    );
                                  }}
                                  checked
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-check-input"
                                  id={pri.name}
                                  onChange={() => {
                                    // setfilterbyprice(pri.value);
                                    // updateDataValue("prices[]", pri.value);
                                    changestate(
                                      setfilterbyprice,
                                      pri.value,
                                      "filterbyprice"
                                    );
                                  }}
                                />
                              )}
                              <label
                                className="custom-control-label form-check-label boxsmalltext"
                                htmlFor={pri.name}
                                key={pri.name}
                              >
                                {pri.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* brands filter start here */}
                    <div className="box3">
                      <div className="collection-collapse-block open">
                        <h4
                          style={{
                            paddingBottom: brand == true ? "0px" : "10px",
                          }}
                          className="collapse-block-title"
                          onClick={() => {
                            setbrand(!brand);
                          }}
                        >
                          BRANDS{" "}
                          {brand == false ? (
                            <i
                              style={{
                                color: "#059fe2",
                                fontFamily: "fontAwesome",
                                fontWeight: "300",
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                top: "4px",
                              }}
                              className="fa-solid fa-minus"
                            ></i>
                          ) : (
                            <i
                              style={{
                                color: "#059fe2",
                                fontFamily: "fontAwesome",
                                fontWeight: "300",
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                top: "4px",
                              }}
                              className="fa-solid fa-plus"
                            ></i>
                          )}
                        </h4>
                        <div
                          className="collection-collapse-block-content"
                          style={{ display: brand == false ? "block" : "none" }}
                        >
                          <div className="size-selector">
                            <div className="collection-brand-filter">
                              {/* {console.log("first-brand-data:",brand_data)} */}

                              {brand_data.slice(0, 8).map((brand_list) =>
                                brand_list.name !== "" ? (
                                  <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
                                    {/* <input
                                      type="checkbox"
                                      className="custom-control-input form-check-input"
                                      id={brand_list.name}
                                    /> */}

                                    {filterbybrand.includes(brand_list.id) ? (
                                      <input
                                        type="checkbox"
                                        className="custom-control-input form-check-input"
                                        id={brand_list.name}
                                        onChange={() => {
                                          // setfilterbybrand("");
                                          // updateDataValue("brand_ids", "");
                                          changestate(
                                            setfilterbybrand,
                                            brand_list.id,
                                            "filterbybrand"
                                          );
                                        }}
                                        checked
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        className="custom-control-input form-check-input"
                                        id={brand_list.name}
                                        onChange={() => {
                                          // setfilterbybrand(brand_list.id);
                                          // updateDataValue(
                                          //   "brand_ids",
                                          //   brand_list.id
                                          // );
                                          changestate(
                                            setfilterbybrand,
                                            brand_list.id,
                                            "filterbybrand"
                                          );
                                        }}
                                      />
                                    )}
                                    <label
                                      className="custom-control-label form-check-label boxsmalltext"
                                      htmlFor={brand_list.name}
                                    >
                                      {brand_list.name}
                                    </label>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* discount filter start here */}
                    <div className="box4">
                      <div className="collection-collapse-block open">
                        <h4
                          style={{
                            paddingBottom: discount == true ? "0px" : "10px",
                          }}
                          className="collapse-block-title"
                          onClick={() => {
                            setdiscount(!discount);
                          }}
                        >
                          DISCOUNT{" "}
                          {discount == false ? (
                            <i
                              style={{
                                color: "#059fe2",
                                fontFamily: "fontAwesome",
                                fontWeight: "300",
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                top: "4px",
                              }}
                              className="fa-solid fa-minus"
                            ></i>
                          ) : (
                            <i
                              style={{
                                color: "#059fe2",
                                fontFamily: "fontAwesome",
                                fontWeight: "300",
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                top: "4px",
                              }}
                              className="fa-solid fa-plus"
                            ></i>
                          )}
                        </h4>
                        <div
                          className="collection-collapse-block-content"
                          style={{
                            display: discount == false ? "block" : "none",
                          }}
                        >
                          <div className="size-selector">
                            <div className="collection-brand-filter">
                              {discount_data.map((discount_list) => (
                                <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
                                  {filterbydiscount.includes(
                                    discount_list.value
                                  ) ? (
                                    <input
                                      type="checkbox"
                                      className="custom-control-input form-check-input"
                                      id={discount_list.name}
                                      onChange={() => {
                                        // setfilterbydiscount("");
                                        // updateDataValue("discounts", "");
                                        changestate(
                                          setfilterbydiscount,
                                          discount_list.value,
                                          "filterbydiscount"
                                        );
                                      }}
                                      checked
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      className="custom-control-input form-check-input"
                                      id={discount_list.name}
                                      onChange={() => {
                                        // setfilterbydiscount("");
                                        // updateDataValue("discounts", "");
                                        changestate(
                                          setfilterbydiscount,
                                          discount_list.value,
                                          "filterbydiscount"
                                        );
                                      }}
                                    />
                                  )}
                                  <label
                                    className="custom-control-label form-check-label boxsmalltext"
                                    htmlFor={discount_list.name}
                                  >
                                    <a href="#">{discount_list.name} </a>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-12">
              {/* {subcategorydetail.length > 0 ? (
                        
                        <div className="col-xl-12 col-lg-12 col-md-12 products" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div className="col-xl-12 col-lg-12 ">
                          <h2 style={{fontWeight:'bold'}}>{categorydetail.category_name}</h2>
                          </div>
                        </div>
                      ) : (
                        <div>
             
                        </div>
                      )} */}

              {subcategorydetail.length > 0 ? (
                <div
                  className="col-xl-12 col-lg-12 col-md-12 products"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "none",
                  }}
                >
                  <div className="col-xl-12 col-lg-12 ">
                    <h2
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#333",
                      }}
                    >
                      {categorydetail.category_name}
                    </h2>
                  </div>
                </div>
              ) : (
                <div>
                  {/* Other content when subcategorydetail.length is not greater than 0 */}
                </div>
              )}

              {/* my test bellow                     */}

              {subcategorydetail.length > 0 ? (
                <div className="sub-category-list">
                  <ul>
                    {subcategorydetail
                      .slice(0, displayedItems)
                      .map((subcat) => (
                        <li className="sub-category-item">
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              nvg("/product-category-detail", {
                                state: { name: subcat.slug, cat_id: subcat.id },
                              });
                            }}
                          >
                            <img src={subcat.logo} alt="Sub Category" />
                            <h5 style={{ fontSize: "14px" }}>
                              {" "}
                              {subcat.category_name}{" "}
                            </h5>{" "}
                          </a>
                        </li>
                      ))}
                  </ul>

                  {subcategorydetail.length > perPage &&
                    displayedItems < subcategorydetail.length && (
                      <button
                        onClick={handleShowMore}
                        className="show-more-button"
                      >
                        Show More
                      </button>
                    )}
                  <h1 className="products"></h1>
                </div>
              ) : (
                ""
              )}

              {/* my test here     top                */}

              <div
                className="col-xl-12 col-lg-12 col-md-12 products"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-xl-7 col-lg-7 d-flex align-items-center responsivetitle">
                  <h4
                    style={{
                      fontSize: "30px",
                      color: "#060407",
                      fontWeight: "300",
                    }}
                  >
                    {/* <b style={{fontSize: "22px", fontWeight: "400"}}> */}
                    {categorydetail.category_name} {/* </b> */}
                    <span>
                      (Showing {projects1.from}-{projects1.to} out of{" "}
                      {projects1.total} products)
                    </span>
                  </h4>
                </div>

                <div className="col-xl-4 col-lg-4 col-sm-12 responsivetitle">
                  <div className="responsivetitle2">
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#333",
                        paddingTop: "10px",
                      }}
                    >
                      Sort By:
                    </label>
                    <select
                      className="form-select sidebar"
                      aria-label=" select example"
                      onChange={(e) => {
                        sortbyfun(e);
                      }}
                    >
                      <option value="id" selected>
                        Default sorting
                      </option>
                      <option value="product_name">Product Name</option>
                      <option value="topsales">Popular</option>
                      <option value="latest">Latest</option>
                      <option value="ASC">Price: low to high</option>
                      <option value="DESC">Price: high to low</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-12" style={{ marginTop: "14px" }}>
                <div className="row">
                  {loadingProducts ? (
                    <Skeleton active />
                  ) : projects?.[0] ? (
                    projects.map((project) => (
                      <div className=" col-lg-4 col-md-6 ">
                        <div
                          className="card rightone"
                          style={{ borderRadius: "0px" }}
                        >
                          <div className="imageheart">
                            {/* {project.wishlist === true ? (
                            <a href="javascript:;" className="addToWish">
                              <img
                                src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                                alt="Wishlist"
                              />
                            </a>
                          ) : (
                            <a
                              href="javascript:;"
                              className="addToWish"
                              style={{ color: "#e3e3e3" }}
                            >
                              <i className="fa fa-heart"></i>
                            </a>
                          )} */}
                            {localdata?.data?.vendor_code !== "S14691" && (
                               project.wishlist === true ? (
                                <a
                                  onClick={() => handleRemoveWishlist(project.id)}
                                  href="javascript:void(0);"
                                  className="addToWish"
                                >
                                  {/* <i className="fa fa-heart"></i> */}
                                  <img
                                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                                  />
                                </a>
                              ) : (
                                <a
                                  onClick={() => handleAddWishlist(project.id)}
                                  href="javascript:void(0);"
                                  className="addToWish"
                                >
                                  <img
                                    src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`}
                                  />
                                </a>
                              )
                            ) }
                           
                          </div>
                        
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              if(localdata?.data?.vendor_code !== "S14691" ){
                                handleAddCartButtonClick(
                                  project.id,
                                  project.product_name,
                                  project.sku,
                                  project.cartonQty
                                )
                              }

                            }
                              
                              
                            }
                          >
                            <img
                              src={project.main_image}
                              alt={project.product_name}
                              // style={{
                              //   padding:'0px 10px',
                              //   width:
                              //     project.thumbnail_image ==
                              //     "https://sbmmarketplace.com/backend/public/uploads/placeholder-medium.jpg"
                              //       ? "267px"
                              //       : "",
                              // }}

                              style={{
                                padding: "0px 10px",
                                width:
                                  project.thumbnail_image ==
                                  "https://sbmmarketplace.com/backend/public/uploads/placeholder-medium.jpg"
                                    ? "267px"
                                    : "",
                                height:
                                  project.thumbnail_image ==
                                  "https://sbmmarketplace.com/backend/public/uploads/placeholder-medium.jpg"
                                    ? "220px"
                                    : "220px",
                              }}
                            />

                            <div className="style-no">
                              <h6>
                                <span className="px-2">
                                  {project.product_name.length > 30
                                    ? `${project.product_name.substr(0, 33)}...`
                                    : project.product_name}
                                </span>
                              </h6>
                            </div>
                            {localdata?.data?.vendor_code !== "S14691" && (
                              <>
                               <div className="Price-box">
                              <p>
                                <span>AED {project.price}</span>
                                {project.regular_price != null ? (
                                  <small
                                    className="text-muted"
                                    style={{
                                      fontSize: "small",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    &nbsp;&nbsp; AED {project.regular_price}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            <div className="Order-price">
                              <span>Ordered Price : AED {project.price}</span>
                            </div>
                              </>
                            ) }
                           

                            <div className="row pt-3">
                              <div
                                className=" col-6 d-flex justify-content-start"
                                style={{ padding: "0px 0px 0px 6px" }}
                              >
                                <p
                                  style={{
                                    position: "relative",
                                    left: "3px",
                                    textWrap: "nowrap",
                                  }}
                                >
                                  {" "}
                                  UNIT:{" "}
                                  {project.product_unit
                                    ? project.product_unit
                                    : "Pcs"}{" "}
                                </p>
                              </div>
                              <div
                                className=" col-6 d-flex justify-content-end "
                                style={{ padding: "0px 6px 0px 0px" }}
                              >
                                <p
                                  style={{
                                    position: "relative",
                                    left: "-3px",
                                    textWrap: "nowrap",
                                  }}
                                >
                                  {" "}
                                  SKU: {project.sku}{" "}
                                </p>
                              </div>
                            </div>
                          </a>
                          {/* <div className="card-body">
                                 <h5 className="card-title">A</h5>
                                 <p className="card-text">b</p>
                                 <p className="card-text">c<small className="text-muted">d</small></p>
                              </div> */}
                              
                                 <div
                                 className="lastbutton"
                                 style={{ width: "100%", padding: "0px" }}
                               >
                                {localdata?.data?.vendor_code !== "S14691" && (
                                 <button
                                   onMouseEnter={() => {
                                     sethovereye(project.id);
                                   }}
                                   onMouseLeave={() => {
                                     sethovereye(0);
                                   }}
                                   className="btn btn-primary one d-flex"
                                   type="button"
                                   style={{
                                     borderRadius: "0px",
                                     width: "50%",
                                     justifyContent: "space-evenly",
                                     alignItems: "center",
                                   }}
                                 >
                                   {" "}
                                   <span>
                                     <i
                                       className="fa-regular fa-eye"
                                       style={{
                                         color:
                                           hovereye == project.id
                                             ? "#fff"
                                             : "#059fe2",
                                       }}
                                     />
                                   </span>{" "}
                                   <span
                                     style={{
                                       fontSize: "15px",
                                       color:
                                         hovereye == project.id ? "#fff" : "#464646",
                                       fontWeight: "500",
                                     }}
                                     onClick={() =>
                                       handleQuickButtonClick(project.id)
                                     }
                                   >
                                     QUICK VIEW
                                   </span>
                                 </button>
                                    ) }
                                 <button
                                   className="btn btn-primary two d-flex"
                                   type="button"
                                   style={{
                                     borderRadius: "0px",
                                     width: "50%",
                                     justifyContent: "space-evenly",
                                     alignItems: "center",
                                     color: "white",
                                   }}
                                   onClick={() => {
                                     nvg("/product-detail/" + project.slug);
                                   }}
                                 >
                                   <span
                                     style={{
                                       fontSize: "15px",
                                       color: "white",
                                       fontWeight: "500",
                                       textWrap: "nowrap",
                                     }}
                                   >
                                     DETAILS VIEW
                                   </span>
                                 </button>
                               </div>
                           
                         
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className=" col-12 ">
                      No products were found matching your selection.
                    </div>
                  )}
                  {/* Pagination component */}
                  {
                    // console.log('per page:',totalPages)
                    // console.log('per length page:',projects.length)
                  }
                  {/* {totalPages > 1  ?
                <div className="row" >
                {projects?.[0] ? (
                  <ReactPaginate
                    pageCount={totalPages}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={(page) => {
                      changepage(page.selected + 1);
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                 ) : (
                  ""
                )} 
              </div>
               :''}  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {/* start quick model popup */}
      <div
        className="modal fade"
        ref={quickpopup}
        id="quickViewModel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header" style={{ borderBottom: "none" }}>
              <button
                ref={quickpopupclose}
                type="button"
                className="btn-close remove-button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  background: " #059fe2",
                  color: "#fff",
                  borderRadius: "0px",
                  fontSize: "20px",
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-lg-6" style={{ marginTop: "20px" }}>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 slider-part">
                        <div className="flexslider galleries">
                          <div>
                            <div className="card-carousel gallery-active">
                              <div
                                className="thumbnails"
                                style={{ position: "relative" }}
                              >
                                <Carousel
                                  showArrows={true}
                                  selectedItem={mainImageIndex}
                                  onChange={(index) => setMainImageIndex(index)}
                                >
                                  {productGalleryData &&
                                    productGalleryData.map((image, index) => (
                                      <div
                                        className="vertical-carousel-wrapper"
                                        key={index}
                                        onClick={() =>
                                          handleMainImageClick(index)
                                        }
                                      >
                                        <img
                                          src={image}
                                          alt={`Image ${index + 1}`}
                                        />
                                      </div>
                                    ))}
                                </Carousel>

                                {productDetails && productDetails.video ? (
                                  <div
                                    className="thumbnail-image"
                                    style={{ marginTop: "115px" }}
                                  >
                                    <video
                                      href="javascript:void(0);"
                                      className="slider-img-pro-detail"
                                      role="button"
                                      onClick={openVModal}
                                    >
                                      <source
                                        src={productDetails.video}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-sm-12"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="row">
                      <h2 className="popup-text text-uppercase">
                        {productDetails && productDetails.product_name
                          ? productDetails.product_name
                          : ""}
                      </h2>
                      <p className="Sku-text">
                        SKU:{" "}
                        {productDetails && productDetails.sku
                          ? productDetails.sku
                          : ""}
                      </p>

                      <div className="action">
                        <a href="#" className="share1">
                          <img
                            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/share.png`}
                            alt="share"
                          />
                          <p className="share-img">Share</p>
                        </a>
                        {/* {productDetails && productDetails.wishlist === true ? (
                        <a href="#" className="wish">
                           <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`} alt="whishlist" />
                           <p className="share-img">Wish</p>
                        </a>
                        ) : (
                        <div className="wish">
                           <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`} alt="whishlist" />
                           <p className="share-img">Wish</p>
                        </div>
                        )} */}
                        {wishlist === false ? (
                          <a
                            onClick={() =>
                              handleAddWishlistQuick(productDetails.id)
                            }
                            href="javascript:void(0);"
                            className="whish"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`}
                            />
                            <p className="share-img">Wish</p>
                          </a>
                        ) : (
                          <a
                            onClick={() =>
                              handleRemoveWishlistQuick(productDetails.id)
                            }
                            href="javascript:void(0);"
                            className="whish"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                            />
                            <p className="share-img">Wish</p>
                          </a>
                        )}
                      </div>

                      <div className="price1">
                        <label htmlFor="">Price:</label>
                        <p>
                          <span>
                            AED{" "}
                            {productDetails && productDetails.uprice
                              ? productDetails.uprice
                              : ""}
                          </span>
                        </p>
                        <span className="smallaed">
                          AED{" "}
                          {productDetails && productDetails.regular_price
                            ? productDetails.regular_price
                            : ""}
                        </span>
                      </div>
                      {productDetails && productDetails.shortFeature ? (
                        <div className="product-detail">
                          <div>
                            <h3 className="text-uppercase label">
                              <strong> Features: </strong>
                            </h3>
                            <br />
                            <div>
                              <div
                                className="mb-2"
                                dangerouslySetInnerHTML={{
                                  __html: productDetails.shortFeature,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: productDetails.short_description,
                              }}
                            ></p>
                          </div>
                          <br />
                          <div className="read-more-btn">
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                handleProDetails(productDetails.slug)
                              }
                              className=""
                              style={{ color: "#007bff", fontWeight: "bold" }}
                            >
                              {" "}
                              <b>Read More</b>{" "}
                            </a>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="order-now-btn">
                        <p>
                          <button
                            onClick={() =>
                              handleAddCartButtonClick(
                                productDetails.id,
                                productDetails.product_name,
                                productDetails.sku,
                                productDetails.cartonQty
                              )
                            }
                          >
                            Order Now
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end quick model popup */}

      {/* add to cart model popup page start */}
      {showModal ? (
        <div
          className="modal fade"
          id="addToCartP"
          ref={cartpopup}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="addToCartPLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close remove-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    background: " #059fe2",
                    color: "#fff",
                    borderRadius: "0px",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <form onSubmit={handleAddToCartSubmit}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="modal-title text-center pb-1 label">
                        {" "}
                        <strong>PLEASE CONFIRM ORDER VARIANT & QUANTITY</strong>
                      </h1>
                      <p className="text-center pb-2">
                        {" "}
                        {product_de && product_de.product_name
                          ? product_de.product_name
                          : ""}
                      </p>

                      <div className="modal-body">
                        <ul className="list-inline">
                          <li className="list-inline-item pl-2 pb-2 mr-4">
                            Item Group :{" "}
                            {product_de && product_de.sku ? product_de.sku : ""}{" "}
                          </li>
                          <li className="list-inline-item">
                            {" "}
                            Carton Qty :{" "}
                            {product_de && product_de.cartonQty
                              ? product_de.cartonQty
                              : "0"}{" "}
                          </li>
                        </ul>

                        {error && (
                          <div
                            className="alert alert-error"
                            onClick={handleAddCartAlertClick}
                          >
                            {error}
                          </div>
                        )}
                        <div className="row">
                          <div className="col-10">
                            <table className="table">
                              <thead>
                                <tr className="label_tabel">
                                  <th scope="col"> Variant </th>
                                  <th scope="col"> Quantity </th>
                                  <th scope="col"> Price </th>
                                  <th scope="col"> Total Price </th>
                                </tr>
                              </thead>
                              <tbody>
                                {cartproductDetails?.[0]
                                  ? cartproductDetails.map(
                                      (variant_info, index) => (
                                        <tr
                                          id={`mp-size-${index}`}
                                          className={`outofstock${variant_info.stock}`}
                                          key={index}
                                        >
                                          <td scope="size">
                                            <div className="checkbox-col">
                                              {convertedArray.find(
                                                (item56) =>
                                                  item56.variant_id ==
                                                  variant_info.id
                                              ) ? (
                                                <FaCheckCircle
                                                  color="#059fe2"
                                                  style={{
                                                    position: "relative",
                                                    top: "-3px",
                                                  }}
                                                />
                                              ) : (
                                                <FaCheckCircle
                                                  color="#d1cfcf"
                                                  style={{
                                                    position: "relative",
                                                    top: "-3px",
                                                  }}
                                                />
                                              )}{" "}
                                              {/* <input type="checkbox"   checked={checkboxStates[index]}
                          onChange={() => {}}  id={`size-v-${index}`} name={`quantity[${variant_info.id}]`} value={variant_info.id} /> */}
                                              <label
                                                htmlFor={`size-v-${index}`}
                                                className="checkbox-label"
                                              >
                                                &nbsp;
                                                {typeof variant_info.item_code ===
                                                "string"
                                                  ? variant_info.item_code.split(
                                                      "-"
                                                    )[1]
                                                  : ""}
                                              </label>
                                            </div>
                                          </td>
                                          {variant_info.stock > 0 ? (
                                            <td className="qty">
                                              <input
                                                type="number"
                                                id={
                                                  product_de &&
                                                  product_de.uprice
                                                    ? product_de.uprice
                                                    : "0.00"
                                                }
                                                onChange={(e) =>
                                                  handleQuantityChange(
                                                    variant_info.id,
                                                    e.target.value,
                                                    e.target.id,
                                                    product_de.id
                                                  )
                                                }
                                              
                                                name={`quantity[${variant_info.id}]`}
                                                placeholder="0"
                                                min="0"
                                                max={
                                                  variant_info.stock
                                                    ? variant_info.stock
                                                    : "0"
                                                }
                                                className="qtyIn"
                                              />
                                              {/* <input type="number" id={product_de && product_de.uprice ? product_de.uprice : '0.00'} onChange={(e) => handleQuantityChange(variant_info.id, e.target.value)} name={`quantity[${variant_info.id}]`} defaultValue={0}  min="0" max={variant_info.stock ? variant_info.stock : '0'} className="qtyIn"  /> */}
                                              {/* setvarient[variant_info.id]= e.target.value} */}
                                            </td>
                                          ) : (
                                            <td className="qty">
                                              <input
                                                type="number"
                                                name={`quantity[${variant_info.id}]`}
                                                placeholder={0}
                                                min="0"
                                                max={
                                                  variant_info.stock
                                                    ? variant_info.stock
                                                    : "0"
                                                }
                                                className="qtyIn disable_qyt"
                                                disabled
                                              />
                                            </td>
                                          )}
                                          <td className="label_addtocart_table">
                                            {" "}
                                            AED{" "}
                                            {product_de && product_de.uprice
                                              ? product_de.uprice
                                              : "0.00"}
                                          </td>
                                          <td className="label_addtocart_table">
                                            AED{" "}
                                            {totalPrices[variant_info.id] ||
                                              "0.00"}
                                          </td>
                                          {/* <td className="label_addtocart_table">AED {quantityupdatedprice && quantityupdatedprice ? quantityupdatedprice : '0.00'} </td> */}
                                          {/* updateallvalue */}
                                        </tr>
                                      )
                                    )
                                  : ""}
                              </tbody>
                            </table>
                          </div>

                          {productSKUDetails !== null ? (
                            <div className="col-2">
                              <table className="table">
                                <thead>
                                  <tr className="label_tabel">
                                    <th scope="col">Related SKU</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productSKUDetails?.[0] ? (
                                    productSKUDetails.map((sku_info) => (
                                      <tr
                                        className="subtotal"
                                        key={sku_info.sku}
                                      >
                                        <td>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              handleProDetails(sku_info.slug)
                                            }
                                            className=""
                                            style={{
                                              fontWeight: "400",
                                              color: "#007bff",
                                            }}
                                          >
                                            {sku_info.sku}
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td>No related SKUs found.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="form-group form-row d-flex justify-content-center mt-2 mb-3 mx-2">
                        <button
                          type="submit"
                          className="btn btn-signup col-12 rounded-0 text-uppercase pt-2 pb-2"
                        >
                          Confirm Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* End add to cart model popup */}
    </div>
  );
};
export default ProductCategory;
