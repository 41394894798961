import React, { useEffect, useState } from "react";
// import Header from '.../components/Header/Header.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';
// import Loader from '../Loader';
const ReturnPolicy = () => {
  //   const [showsidebar, setshowsidebar] = useState(false);

  return (

    <div>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
                 <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section class="bulkorder-sec pb-5 mt-5">
        <div class="container">

          <div class="row d-flex justify-content-center">

            <div class="bulkorder-heading text-center mt-5">
              <h3> Returns and Exchange Policy </h3>
            </div>
            < div className="row w-75 ml-auto mr-auto mt-4 ct_privacy_para" >
             

              <p> To complete your return/exchange, we require a receipt or proof of purchase. We reserve the right to refuse returns/exchanges of items that does not meet our conditions in our sole discretion..</p>
              <div className="privacysmalltext">
                <p className="bold">CONDITIONS FOR RETURNS/EXCHANGES</p>
              </div>
                <div className="privacysmalltext">
                <p className="bold">To be eligible for a return/exchange</p>
                </div>


                <div className="privacysmalltext">
                <p>Your item must be unused and in the same condition that you received it</p>
				<p> It must also be in the original packaging.</p>
				<p> A Debit Note/ Purchase return might be required. </p>
                </div>
				
				
				<div className="privacysmalltext">
                <p className="bold">The following items cannot be returned/exchanged</p>
                </div>


                <div className="privacysmalltext">
                <p>Items made to your specifications or personalized according to your request.</p>
				<p>Items which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</p>
		        </div>

                <div className="privacysmall">
                <p className="bold"> REFUNDS FOR RETURNS:</p>
                <p>1. Once your return is received and inspected, we will notify you of the approval or rejection of your refund. If approved, your refund will be processed accordingly within 5 - 7 business days.</p>
                <p className="mt-2">2. Refunds will be done only through the Original Mode of Payment.</p>
                </div>


                <div className="privacysmall">
                <p className="bold">Late or missing refunds:</p>
                <p>If you haven’t received a refund within the specified number of days, check your bank account, then contact your credit card company. It may take some time before your refund is officially posted. If you’ve done all the above and still have not received your refund, please contact us at help@sbmmarketplace.com or contact your designated salesperson for further assistance.</p>
                </div>


                <div className="privacysmalltext">
				<p className="bold">Shipping for Returns/Exchanges:</p>
                <p>You can either choose to drop-off in-Store or send your returns/exchanges by courier or to our delivery vehicle. If by courier, you will be responsible for shipping.</p>
                </div>

            

            </div>
          </div>
        </div>
      </section>



      <Footer />
    </div>

  )
}

export default ReturnPolicy;