import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
// import Loader from '../Loader';
import { Helmet } from "react-helmet";
const TermsConditions = () => {
  //   const [showsidebar, setshowsidebar] = useState(false);
  // const [loading, setLoading] = useState(true);
  return (
    <div>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
      {/* {loading ? <Loader /> : null} */}
      <Helmet>
        <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section class="bulkorder-sec pb-5">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="bulkorder-heading text-center mt-5">
              <h3> Terms & Conditions </h3>
            </div>
            <div className="row w-75 ml-auto mr-auto mt-4 ct_privacy_para">
             
              <p className="bold">
              Governing Law : 
              </p>
            
			   <p>
			   Inclusion is mandatory and should have any one of the below mentioned points.
                Any purchase, dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of UAE.
              </p>
             
			   
                <p className="bold">
                Method of Payment, Card Types accepted and Currency:
				</p>
				 
			    <p> 
				Method of Payment must include all the details related to payment such as Payment method, Card Types accepted,
				Currency etc.
				"We accept payments online using Visa and MasterCard credit/debit card in AED (or any other agreed currency). 
				The logo of the accepted cards are to be mentioned on their Home page and on their Payment check out page. </p>
				<p className="bold">Delivery & Shipping Policy: </p>
				<p>
				 Once the order is placed the process they will be following to deliver/ship the items/goods, conditions and the time duration for the products to reach the customers.
				  "(Website) will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE". Policy of multiple shipments has to be mentioned on their website. At the time of checkout disclose to the cardholder,
				 the possibility that multiple shipments may result in multiple postings to the cardholder's monthly statement.</p>
				<p className="bold">
				Refund/Return Policy: </p>
				<p>
				Refund policy is the Compensation paid to a customer for over-invoicing or for returned goods which is to be mentioned on the website if allowed and the conditions under which refunds will be given to the customers.
				 "Refunds will be done only through the Original Mode of Payment". </p>
				<p className="bold">
				Governing Law and Jurisdiction: </p>
				<p>
				Law governing the Merchants business, the Customer and the transactions carried out while the customer is using the Ecommerce website. "Any purchase, dispute or claim arising
                out of or in connection with this website shall be governed and construed in accordance with the laws of UAE". 
               
              </p>
			  
              
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TermsConditions;
