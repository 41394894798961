import React, { useEffect, useState } from "react";
// import Header from '.../components/Header/Header.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';
// import Loader from '../Loader';
const CancellationPolicy = () => {
  //   const [showsidebar, setshowsidebar] = useState(false);

  return (

    <div>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
                 <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section class="bulkorder-sec pb-5 mt-5">
        <div class="container">

          <div class="row d-flex justify-content-center">

            <div class="bulkorder-heading text-center mt-5">
              <h3> Cancellation & Replacement Policy </h3>
            </div>
            < div className="row w-75 ml-auto mr-auto mt-4 ct_privacy_para" >
             SBM believes in serving our customers as far as possible, and therefore has a liberal cancellation and return policy.
             Please contact us at help@sbmmarketplace.com or our sales team for everything related to return, cancellation, and refund.



              <div className="privacysmalltext">
                <p className="bold">Cancellation Policy:</p>
				  <p> Cancellations will be considered only if the request is made within 48 hours of placing an order.
                  </p>
				  <p> <b>100 % refund will be provided on the invoice amount (T & C apply) </b></p>
				
              </div>
                <div className="privacysmalltext">
                    <p className="bold">Return & replacement due to manufacturing defect:</p>
				    <p> We usually collect items within 24-48 hours from the date of your feedback. The delivery of replacement usually takes 2-3 working days from when we have received and evaluated the product. 
                    Note: Return and replacement apply to products that are delivered within the UAE only. You can contact us to check on the terms if you are outside the UAE.
                     </p>
                </div>

                <div className="privacysmalltext">
                  <p className="bold">Within UAE:
                  </p>
				  <p>Collection – Free of charge</p>
				  <p>Delivery of replacement – Free of charge </p>
				 </div>
				 
				 
				 <div className="privacysmalltext">
                  <p className="bold">Outside UAE:
                  </p>
				  <p> Refund will be provided with an online store credit less the shipping cost of the returned item on a case-to-case basis.</p>
				  <p> Email us at help@sbmmarketplace.com to discuss on this. </p>
				 </div>
				
				

            </div>
          </div>
        </div>
      </section>



      <Footer />
    </div>

  )
}

export default CancellationPolicy;