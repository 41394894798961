import React, { useEffect, useState } from "react";
// import Header from '.../components/Header/Header.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';
// import Loader from '../Loader';
const ReturnPolicy = () => {
  //   const [showsidebar, setshowsidebar] = useState(false);

  return (

    <div>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
                 <Helmet>
       <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section class="bulkorder-sec pb-5 mt-5">
        <div class="container">

          <div class="row d-flex justify-content-center">

            <div class="bulkorder-heading text-center mt-5">
              <h3> About us </h3>
            </div>
            < div className="row w-75 ml-auto mr-auto mt-4 ct_privacy_para" >
             

                 <div className="privacysmalltext">
                 <p> SBM International Trading FZCO and Souvenir Building Materials LLC (SBM Group Companies) are leading entities in the realm of personal protective equipment (PPE) and Occupational Health and Safety solutions, with operations based in the UAE spanning across Dubai, Jebel Ali, Abu Dhabi, and Sharjah. and are run by some of the experts in the field of personal protective equipment (PPE) renowned for our commitment to excellence and innovation.</p>
                 </div>


                <div className="privacysmalltext">
                <p>Our extensive product line has made significant contributions to various sectors such as Oil & Gas, Marine, Industrial, and Construction across the UAE, Middle East, Africa, and CIS Countries.</p>
			    </div>
				
				
				<div className="privacysmalltext">
                <p>Our portfolio features esteemed brands like Vaultex, Zecchin and Workland, alongside our distributorship of HONEYWELL’s esteemed PPE product line.
                </p>
                </div>


                <div className="privacysmalltext">
                <p>The products include safety shoes, safety glasses, Chemical & Dust Goggles, Face Shield Visor, Headgear, Ear Plugs, Ear Defender, Face Masks, Disposable Respirators, Welding Helmet, Welding Glasses, Safety Helmet, Scaffolding Tags, Full Body Safety Harness, Fall Arresting Devices, Rescue Winches, Tripods, Lifting Equipment, Eye Wash Stations, Coveralls (Polyester/Cotton & Cotton), Work Uniforms (Cotton & Polyester /Cotton), Disposable Coveralls, Gloves (PVC Dotted, Leather Rigger & Welding), High Visibility Reflective Vest, etc.</p>
			    </div>

                <div className="privacysmall">
                <p className="bold"> Our Vision:</p>
                <p>To Provide Ultimate Safety in Working environments.</p>
                </div>


                <div className="privacysmall">
                <p className="bold">Our Mission:</p>
                <p>We aim to be the leading PPE supplier, by providing innovative and high quality ergonomic personal protective equipment (PPE) at a competitive price without compromising safety, comfort, and style.</p>
                </div>


              

            

            </div>
          </div>
        </div>
      </section>



      <Footer />
    </div>

  )
}

export default ReturnPolicy;